import PropTypes from 'prop-types';
import { createContext, useEffect, useReducer, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';

// reducer - state management
import { LOGIN, LOGOUT, SET_USER } from 'store/actionType';
import accountReducer from 'store/accountReducer';

// project imports
import Loader from 'ui-component/Loader';
import axios from 'utils/axios';
import { post, put } from 'utils/axiosRequests';

import { globalAction } from 'store';
// constant
const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const setSession = (serviceToken) => {
    if (serviceToken) {
        localStorage.setItem('serviceToken', serviceToken);
        axios.defaults.headers.common.Authorization = `Bearer ${serviceToken}`;
    } else {
        localStorage.removeItem('serviceToken');
        delete axios.defaults.headers.common.Authorization;
    }
};

// ==============================|| JWT CONTEXT & PROVIDER ||============================== //
const JWTContext = createContext(null);

export const JWTProvider = ({ children }) => {
    const [state, dispatch] = useReducer(accountReducer, initialState);
    const [sendOTPEmail, setSendOTPEmail] = useState('');
    const storeDispatch = useDispatch();
    const verifyEmail = (email) => {
        setTimeout(
            () => {
                setSendOTPEmail('');
            },
            1000 * 60 * 10
        );
        return setSendOTPEmail(email);
    };
    const navigate = useNavigate();
    const init = async () => {
        try {
            const serviceToken = window.localStorage.getItem('serviceToken');
            if (serviceToken) {
                setSession(serviceToken);
                const response = await axios.get('/api/customers/me');
                if (response.data.status === 'success') {
                    dispatch({
                        type: LOGIN,
                        payload: {
                            isLoggedIn: true,
                            user: response.data.user
                        }
                    });
                } else {
                    dispatch({
                        type: LOGOUT
                    });
                }
            } else {
                dispatch({
                    type: LOGOUT
                });
            }
        } catch (err) {
            console.error(err);
            dispatch({
                type: LOGOUT
            });
        }
    };
    useEffect(() => {
        init();
    }, []);

    const login = async (email, password) => {
        try {
            let response = await axios.post('/api/customers/login', { email, password });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response?.statusType === 'notVerified') {
                verifyEmail(email);
            }
            if (response.status === 'success') {
                setSession(response.accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const loginWithOTP = async (email, otp) => {
        try {
            let response = await axios.post('/api/customers/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSendOTPEmail('');
                setSession(response.accessToken);
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const otpVerification = async (email, otp, pageType) => {
        try {
            let response = await axios.post('/api/customers/verify-otp', { email, otp });
            response = response.data;
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
            }

            if (response.status === 'success') {
                setSendOTPEmail('');
                setSession(response.accessToken);
                if (pageType === 'forgot') {
                    navigate('/reset-password', { replace: true, state: otp });
                } else {
                    navigate('/', { replace: true, state: otp });
                }
                // dispatch({
                //     type: SET_USER,
                //     payload: {
                //         isLoggedIn: true,
                //         user: response.user
                //     }
                // });
                dispatch({
                    type: LOGIN,
                    payload: {
                        isLoggedIn: true,
                        user: response.user
                    }
                });
            }
        } catch (error) {
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };
    function HandleUpdateProfile(body) {
        return new Promise((resolve, reject) => {
            put('/api/users/update-profile', body)
                .then((res) => {
                    resolve(res.data);
                    init();
                })
                .catch((err) => {
                    reject(err);
                });
        });
    }
    const logout = () => {
        setSession(null);
        setSendOTPEmail('');
        dispatch({ type: LOGOUT });
        navigate('/login');
    };

    const resetPassword = async (values) => {
        try {
            let response = await axios.post('/api/customers/forget-password', values);
            response = response.data;
            console.log(response, 'response');
            if (response?.message) {
                storeDispatch(globalAction('SHOW_TOASTER', { status: response.status, message: response.message }));
                window.localStorage.removeItem('forgotPasswordEmail');
            }
            navigate('/');
        } catch (error) {
            console.log(error, 'error');
            storeDispatch(globalAction('SHOW_TOASTER', { status: 'error', message: 'Something wents wrong! Please try again!' }));
        }
    };

    const sendOTP = (email) => axios.post('/api/customers/send-otp', email);

    const registration = (data) => post('/api/customers/signup', data);

    if (state.isInitialized !== undefined && !state.isInitialized) {
        return <Loader />;
    }

    return (
        <JWTContext.Provider
            value={{
                ...state,
                sendOTPEmail,
                verifyEmail,
                login,
                logout,
                sendOTP,
                otpVerification,
                resetPassword,
                registration,
                loginWithOTP,
                HandleUpdateProfile
            }}
        >
            {children}
        </JWTContext.Provider>
    );
};

JWTProvider.propTypes = {
    children: PropTypes.node
};

export default JWTContext;
