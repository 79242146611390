// third-party
import { createSlice } from '@reduxjs/toolkit';

// project imports
import { dispatch } from '../index';
import axios from 'axios';
import { API_URL } from 'config';

// ----------------------------------------------------------------------

const initialState = {
    packageDetail: null,
    priceDetails: null
};

const slice = createSlice({
    name: 'packageDetail',
    initialState,
    reducers: {
        // HAS ERROR
        hasError(state, action) {
            state.error = action.payload;
        },

        getPackageSuccess(state, action) {
            state.packageDetail = action.payload;
        }
    }
});

// Reducer
export default slice.reducer;
export const { getPriceDetails } = slice.reducer;

// ----------------------------------------------------------------------

export function getPackage(id) {
    return async () => {
        try {
            const response = await axios.get(`${API_URL}/api/packages/${id}`);
            dispatch(slice.actions.getPackageSuccess(response.data.data));
        } catch (error) {
            dispatch(slice.actions.hasError(error));
        }
    };
}
