import useAuth from 'hooks/useAuth';
import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

function SubscriptionGuard({ children }) {
    const navigate = useNavigate();
    const { user } = useAuth();

    // useEffect(() => {
    //     if (!user?.isSubscribe) {
    //         navigate('/dashboard/profile');
    //     }
    // }, [user, navigate]);
    return children;
}

export default SubscriptionGuard;
